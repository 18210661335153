@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@400&family=Work+Sans:wght@400&display=swap');

body {
  margin: 0;
  font-family: 'Bitter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: 'Bitter', sans-serif !important;
}





