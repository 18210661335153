@import url(https://fonts.googleapis.com/css2?family=Bitter:wght@400&family=Work+Sans:wght@400&display=swap);
body {
  margin: 0;
  font-family: 'Bitter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: 'Bitter', sans-serif !important;
}






@-webkit-keyframes spinner {
 0% {
   -webkit-transform: rotate(0deg);
           transform: rotate(0deg);
 }
 100% {
   -webkit-transform: rotate(360deg);
           transform: rotate(360deg);
 }
}
@keyframes spinner {
 0% {
   -webkit-transform: rotate(0deg);
           transform: rotate(0deg);
 }
 100% {
   -webkit-transform: rotate(360deg);
           transform: rotate(360deg);
 }
}
.loading-spinner {
 width: 25px;
 height: 25px;
 border: 5px solid #f3f3f3; /* Light grey */
 border-top: 5px solid #383636; /* Black */
 border-radius: 50%;
 -webkit-animation: spinner 1.5s linear infinite;
         animation: spinner 1.5s linear infinite;
}

.spinner-container {
 display: grid;
 justify-content: center;
 align-items: center;
 height: 25px;
}

.body {
  background-color: white;

}

.light-mode {
  background-color: white;

}

.dark-mode {
  background-color: black;
}


* {
  font-family: Noto Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Noto Sans,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  letter-spacing: 0.5px;
}


.reviewtext {
  color: black;
  font-family: Noto Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Noto Sans,
  Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
  Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji, monospace;

}

.reviewtext2 {
  color: black;

  font-family: Noto Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Noto Sans,
  Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
  Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji, monospace;

}



table {

  padding-left: 3px;
  padding-right: 3px;

 
}

tr {

    padding-left: 3px;
    padding-right: 3px;

   
  }

tbody {

  padding-left: 3px;
  padding-right: 3px;

 
}

th {

    padding-left: 3px;
    padding-right: 3px;
  
  }
  

td {

 
  padding-left: 3px;
  padding-right: 3px;
}

.tablePadding {
  padding-left: 5px;
  padding-right: 5px;
}

.tableMargin {
  margin-left: 5px;
  margin-right: 5px;
  background-color: yellowgreen;
}

.h1 { margin-top: 0; }


.movietitle {
  font-size: .8em;
 height: 3.2em;
  width:135px;
  flex-wrap: nowrap;
color: white;

}

.glow__poster {
  
  display: flex;
 flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 250px;
    max-width: 50%;
    flex-wrap: wrap;
 
}

.gbar {

  background-color: #7FFFD4;
  
  font-size: .7em;

  display: flex;

}

.gbarcomment {

  background-color:  #143B5E;
  
  font-size: .7em;

  display: flex;

}

.gbar2 {
  border-radius: 4px !important;
  background-color: #143B5E;
  color: black;
padding: 2px;
  
font-size: .7em;

justify-content: center;
align-items: center;

display: flex;
}

.gbar4 {

  font-family: Noto Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Noto Sans,
  Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
  Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji, monospace;

}

.gbar5 {

  font-family: Noto Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Noto Sans,
  Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
  Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji, monospace;

}



pre {
 white-space: pre-wrap;
 word-wrap: break-word;
 text-align: justify;

}

.gbar3 {
 border-radius: 4px !important;
 background-color: white;
 
padding: 2px;
 
font-size: .7em;

justify-content: bottom;
align-items: bottom;

display: flex;
}

.searchbar {
  color: black;

  height: 45px;
  align-items: center;

  justify-content: center;


  display: flex;
  flex-direction: column;
  margin: 20px;
}

.descbar {

  height: 175px;
  display: grid;
  align-items: left;
justify-content: top;
  grid-gap: .1rem;
  gap: .1rem;
  margin: 5px;
  padding: 0px 5px 0px 5px;
max-width: 100%;
font-size: .8em;
color: white;
}





.row {
  margin-left: 10px;
}

.row__poster {
  object-fit: contain;
  width: 100%;
  max-height: 170px;
  margin-right: 10px;
  transition: -webkit-transform 450ms;
  transition: transform 450ms;
  transition: transform 450ms, -webkit-transform 450ms;
}



.row__poster:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.row__posters {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
}

.row__posters2 {

  overflow-y: hidden;
 



 margin:10px;
padding: 5px;
position: relative;

 

 grid-gap: 1rem;

 

 gap: 1rem;

 grid-column-gap: 1em;

 -webkit-column-gap: 1em;

         column-gap: 1em;

max-width: 200%;

  background-color:black;


display: grid;
grid-auto-flow:column;
grid-template-rows: 1fr 1fr;
overflow-x: auto ;

}


.card3 {
  
    
 
 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
   
 
 
 background-color: transparent;
 
 position: relative;
 border-radius: .35rem;
 box-shadow: 0 0 3px 3px rgba(0,0,0,0.5);

 
 transition: 0ms;
 cursor: pointer;
 -webkit-transform: perspective(1000px) rotateY(0) translateY(0);
         transform: perspective(1000px) rotateY(0) translateY(0);
 -webkit-transform: perspective(1000px) rotateY(var(--rotate-y,0)) translateY(var(--translate-y,0));
         transform: perspective(1000px) rotateY(var(--rotate-y,0)) translateY(var(--translate-y,0));

 font-size:.8em;
    
  
  
 
 
  

}

.card2 {
    flex-direction: column;
  justify-content: center;
  align-items: center;
 background-color: black;
 color: white;
 position: relative;
  transition: 0ms;
 cursor: pointer;
 -webkit-transform: perspective(1000px) rotateY(0) translateY(0);
         transform: perspective(1000px) rotateY(0) translateY(0);
 -webkit-transform: perspective(1000px) rotateY(var(--rotate-y,0)) translateY(var(--translate-y,0));
         transform: perspective(1000px) rotateY(var(--rotate-y,0)) translateY(var(--translate-y,0));
}


.card2.flip {
  color: black;
    background-color: white;
    
  }

  .cardcomment {
    flex-direction: column;
  justify-content: center;
  align-items: center;
 background-color: purple;
 color: white;
 position: relative;
  transition: 0ms;
 cursor: pointer;
 -webkit-transform: perspective(1000px) rotateY(0) translateY(0);
         transform: perspective(1000px) rotateY(0) translateY(0);
 -webkit-transform: perspective(1000px) rotateY(var(--rotate-y,0)) translateY(var(--translate-y,0));
         transform: perspective(1000px) rotateY(var(--rotate-y,0)) translateY(var(--translate-y,0));
}


.cardcomment.flip {
  color: black;
    background-color: #143B5E;
    
  }


.card2 .back {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  height: 225px;
  max-width: 100%;

  font-size:.5em;
  visibility: hidden;
  color:purple;
  background-color: yellow;
}

.back2 {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  height: 225px;
  max-width: 100%;

  font-size:.5em;
  visibility: hidden;
}



.card {
  
    
 
    flex-direction: column;
     justify-content: center;
     align-items: center;
  
      
    
    
    background-color: blue;
    
    position: relative;
    border-radius: .35rem;
    box-shadow: 0 0 3px 3px rgba(0,0,0,0.5);
   
    
    transition: 0ms;
    cursor: pointer;
    -webkit-transform: perspective(1000px) rotateY(0) translateY(0);
            transform: perspective(1000px) rotateY(0) translateY(0);
    -webkit-transform: perspective(1000px) rotateY(var(--rotate-y,0)) translateY(var(--translate-y,0));
            transform: perspective(1000px) rotateY(var(--rotate-y,0)) translateY(var(--translate-y,0));
  
    

  }
  

.card .front {
  

  height: 225px;
    max-width: 100%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  

}



.card .back {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  height: 500px;
  max-width: 100%;

  font-size:.5em;

}
.card .back p{
  height: 225px;
    max-width: 150%;
color: blue;
}

.textwhite {
  color: yellow;
}



pre span.flip{
  color: red;     
}

.card-grid {
  display: grid;
  align-items: center;

  grid-gap: .1rem;

  gap: .1rem;
  margin: 5px 5px 5px 5px;
  padding: 0px;
max-width: 100%;

} 


.card:hover {
--translate-y: -2px;
box-shadow: 0 0 5px 2px rgba(0,0,0,0.5);
opacity: 1;
}

.card.flip {
color:white;
  background-color: #000000;
  
}




.card .front,
.card .back {
  position: absolute;
 
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;


  
 
}



 



.item1 {
  
  align-items: center;
}

.item2 {
  justify-self: center;
  align-items: center;
}

.item3 {
  justify-self: center;
  align-items: center;
}

.check-space {
  margin-right: 10px;
}

.sub-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.sub {
  margin: 5px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: left;
  justify-content: left;
}
.sub-title {
  font-weight: bold;
}

.sub-details {
  margin-bottom: 0.5rem;
}

.initial-padding {
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
}



.center {
  margin: 5px;
  padding: 5px;
  color: white;
 background-color: #000000;
font: black;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 5px;


}

.center2 {

  color: white;
height: 25px;

  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  font-style: italic;

}


.center3 {

  height: 30px;
  padding: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  margin: 7px;
  color: white;
  }

.parentcenter {
  margin: 5px;
  padding: 5px;

  flex-direction: row;
  justify-content: top;
  align-items: left;

  border-radius: 5px;


}



.pre {
 white-space:pre-wrap;
}


.write-center {
  padding: 5px;
  margin: 7px;
  display: flex;
  flex-direction: column;
  color: white;
}

.write-center2 {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
}

.write-center3 {
 padding: 5px;
 margin: 10px;
 display: flex;
 flex-direction: column;
}

.write-center4 {
  padding: 5px;
 
  margin: 3px;
  display: flex;
  flex-direction: column;
 }
 
 .write-center5 {
  padding: 5px;
  margin: 7px;
  display: flex;
  flex-direction: column;
  color: lightblue;
}

.write-center6 {
  padding: 5px;
  margin: 7px;
  display: flex;
  flex-direction: column;
  color: black;
  background-color: black;
}


.navbar {
  color: black;
  background-color: #143B5E;
  height: 70px;
  align-items: center;

  justify-content: center;
 
  display: flex;
  flex-direction: column;
 
  font-size: .9em;

 


}

.searchbar {
  color: black;

  height: 35px;
  align-items: center;

  justify-content: center;


  display: flex;
  flex-direction: column;
  margin: 5px;
}






.left {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  margin: auto;
  padding: 30px 75px 30px 75px;
}

/* Style page content */
.main {
  margin-left: 0px; /* Same as the width of the sidebar */
  padding: 0px 10px;
}

a:link {
  text-decoration: none;
  color: white;
}

a:visited {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: none;
  color: white;
}

a:active {
  text-decoration: underline;
}

.navbar-nav {
color: white;
  margin:0px;
  padding:0px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



/* Not sure what this does */
.ce-chat-list {
  background-color: #143B5E!important ;
}

/* Left Sidebar */

.ce-chats-container {
  background-color:  #143B5E !important;
}

/* The Chat that is selected */

.ce-active-chat-card {
  background-color:white !important;
  border: 4px solid #cabcdc;
  border-radius: 0px !important;
}

.ce-chat-subtitle-text {
  color: black !important;
}

/* My Chats Top Menubar */
.ce-chat-form-container {
  padding-bottom: 20px;
 
  color: black !important;
}

/* Shows Button and Input Text Bar */
.ce-text-input {
  border-radius: 6px;

  background-color: white;
  color: black;
}

/*  */
.ce-primary-button {
  border-radius: 6px; 
  background-color: lightblue!important;
  color: white;
  position: relative;
  bottom: 5px;
}

.ce-send-message-button {
  text-align: top !important;
  line-height: 0.5em !important;
  padding: 0px !important;
}

svg {
  text-align: top !important;
  line-height: 1.5em !important;
  padding: 0px !important;
}

/* Plus Button and Icons */

text,
svg {
  vertical-align: top !important;
  color: red!important;
}

.anticon {
  vertical-align: 0.45em;
}

.ce-danger-button {
  background-color: #143B5E!important;
  border-radius: 22px !important;
}

/* Right Panel Background */

.ce-settings {
  background-color:  #143B5E !important;
}

.ce-autocomplete-input {
  border-radius: 6px !important;
  border: 1px solid #3b2a50 !important;
}

.ce-autocomplete-options {
  border-radius: 6px !important;
  border: 1px solid #3b2a50 !important;
  background-color: #143B5E!important;
}

.ce-chat-settings-container {
  padding-top: 12px !important;
  background-color: white;

}

.ce-chat-avatars-row {
  display: none !important;
  background-color: brown;
}

/* CUSTOM FEED */

.chat-feed {
  height: 100%;
  width: 90%;
  overflow: scroll;
  background-color: yellow;
}

.chat-title-container {
  width: calc(100% - 36px);
  padding: 18px;
  text-align: center;
  color: yellow;
}

.chat-title {
  color: #7554a0;
  font-weight: 800;
  font-size: 24px;
}

.chat-subtitle {
  color: #7554a0;
  font-weight: 600;
  font-size: 12px;
  padding-top: 4px;
  
}

.message-row {
  float: left;
  width: 100%;
  display: flex;
  margin-left: 18px;
  color: black;
}

.message-block {
  width: 100%;
  display: inline-block;
  color: black;
}

.message-avatar {
  width: 44px;
  height: 44px;
  border-radius: 22px;
  color: black;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 48px;
}

.message {
  padding: 12px;
  font-size: 16px;
  border-radius: 6px;
  max-width: 60%;
  color: black;
}

.message-image {
  margin-right: 18px;
  object-fit: cover;
  border-radius: 6px;
  height: 30vw;
  /* width: 30vw; */
  max-height: 200px;
  max-width: 200px;
  min-height: 100px;
  min-width: 100px;
}

.read-receipts {
  position: relative;
  bottom: 6px;
}

.read-receipt {
  width: 13px;
  height: 13px;
  border-radius: 13px;
  margin: 1.5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
}

.message-form-container {
  position: absolute;
  bottom: 0px;
  width: calc(100% - 36px);
  padding: 18px;
  background-color: black;
  color: #143B5E!important;
}

.message-form {
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #3b2a50;
  background-color: black;
}

.message-input {
  height: 40px;
  width: calc(100% - 132px);
  background-color:black;
  border: 1px solid white;
  padding: 0px 18px;
  outline: none;
  font-size: 15px;
}

.image-button {
  cursor: pointer;
  padding: 0px 12px;
  height: 100%;
}

.send-button {
  height: 42px;
  background-color: black;
  border: 1px solid white;
  padding: 0px 18px;
  cursor: pointer;
}

.send-icon {
  top: 1px;
  position: relative;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.picture-icon {
  top: 1px;
  position: relative;
  font-size: 14px;
}

/* FORM STYLES */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62, 5%;
}

.wrapper {
  height: 75vh;
  width: 90%;
  background: rgb(117, 84, 160);
  background: linear-gradient(
    90deg,
    rgba(117, 84, 160, 1) 7%,
    rgba(117, 84, 160, 1) 17%,
    rgba(106, 95, 168, 1) 29%,
    rgba(99, 103, 174, 1) 44%,
    rgba(87, 116, 184, 1) 66%,
    rgba(70, 135, 198, 1) 83%,
    rgba(44, 163, 219, 1) 96%,
    rgba(22, 188, 237, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
}



.title {
  text-align: center;
  color: white;
  margin-bottom: 30px;
  width: 100%;
  font-size: 2.3em;
}

.button {
  border-radius: 4px;
  border: none;
  background-color: white;
  color: black;
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  padding: 20px;
  width: 200px;
  transition: all 0.4s;
  cursor: pointer;
  margin: 5px;
  width: 90%;
}
.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.4s;
}
.button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}
.button:hover span {
  padding-right: 25px;
}
.button:hover span:after {
  opacity: 1;
  right: 0;
}



body {
  background-color: #000000;
  margin: 0;
}

.container {
  max-width: 900px;
  margin: 1rem 2rem;
  color: #143B5E!important;
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  background-color: white;
  padding: 0 1.5rem;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);
}

.form {
  width: 350px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin: .5rem;
}

.form-group > label {
  color: yellow;
  font-size: .75rem;
  margin-bottom: .25rem;
}

.btn {
  background-color: hsl(200, 100%, 50%);
  color: white;
  padding: .5em 1em;
  border-radius: .3em;
  border: none;
  cursor: pointer;
}

.btn:hover {
  background-color: hsl(200, 100%, 40%);
}

.card-grid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: .5rem;
  gap: .5rem;
}




.flashcard-options {
  margin-top: .5rem;
}

.flashcard-option {
  margin-top: .25rem;
  color: #555;
  font-size: .75rem;
}

.flashcard-option:first-child {
  margin-top: 0;
}
